import React from "react"
import { Link } from "gatsby"
import "../components/layout.css"

const BetaSignup = () => {
  return (
    <div className="w-full h-screen gradient md:p-16">
      <h1 className="text-center text-white">Get Beta Access</h1>
      <p className="text-center text-white">
        Sign up for beta access and be among the first to try Explot.
      </p>
      <form
          className="w-4/5 md:w-2/3 max-w-xs mx-auto shadow-custom p-8 rounded bg-white"
          name="closed-beta-signup"
          method="POST"
          //@ts-ignore
          data-netlify="true"
          onSubmit={(e) => {
            if(e.target[2].value.length <= 1) {
              e.preventDefault()
              alert('Please enter an email we can notify you on')
            }
          }}
        >
          <input
            type="hidden"
            className="hidden"
            name="form-name"
            value="closed-beta-signup"
          />
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="email"
            >
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              name="name"
              placeholder="Jack Black"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="email"
            >
              E-mail
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="text"
              name="email"
              placeholder="jane.goodman@mail.com"
            />
          </div>
          <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="email"
            >
              Occupation (optional)
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="occupation"
              type="text"
              name="occupation"
              placeholder="Data Analyst"
            />
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              What do you hope to achieve by using Explot?          
            </label>
            <textarea
              id="text"
              name="reason"
              type="text"
              placeholder="..."
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            ></textarea>
          </div>
          <div className="flex items-center justify-between">
            <input
              className="items-center shadow justify-center px-2 py-1 border border-transparent text-base leading-6 rounded text-white bg-light-blue hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2"
              type="submit"
              value="Send"
            />
          </div>
        </form>
    </div>
  )
}

export default BetaSignup